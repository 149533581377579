import axios from '@axios'

import router from '@/router'   

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTags(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getallquestiontags`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSurveyAbs(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyAbs/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAttendeResultByItemId(ctx,   queryParams  ) {
    
  if (queryParams==null||queryParams.id==undefined) {
        return null;
      }
      console.log("incoming attendee results",{ params: queryParams });
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getAttendeResultByItemId`,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    

    getSurveyAbsGlobal(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyAbsGlobal/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSurveyAttendeResults(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyAttendeResults/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSurveyItemsAbs(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getsurveysubjectsAbs/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    

    fetchSurveyAttendees(ctx,   queryParams  ) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyItemAttendees/${queryParams.id}`,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSurvey(ctx, { id }) {
      console.log("single course  Fetching" );
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/surveydetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubjects(ctx, queryParams) {
     
        return new Promise((resolve, reject) => {
          axios
            .get(`/survey/admin/getsurveysubjects/${queryParams.id}`, { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
  
    

    fetchCourses(ctx, queryParams) {
      console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/surveys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

     
  },
}
